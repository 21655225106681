<template>
  <div>
    <v-container>
      <Timestamp v-model="timestamp" :time.sync="time" :date.sync="date">
        <v-row>
          <v-col>
            <v-text-field
              label="Data"
              type="date"
              v-model="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Hora"
              type="time"
              v-model="time"
            ></v-text-field>
          </v-col>
        </v-row>
      </Timestamp>
      <v-alert type="info"> {{ timestamp }} </v-alert>
    </v-container>
  </div>
</template>

<script>
import Timestamp from "@/components/tools/Timestamp";

export default {
  data() {
    return {
      timestamp: "1997-01-10 13:00:00",
      date: "",
      time: "",
    };
  },
  components: {
    Timestamp,
  },
};
</script>